/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Container from "./container"
import Text from "./text"
import Row from "./row"
import Column from "./column"

import { useMedia } from "react-use"
import { mediaQueryList } from "../helpers"
import LinkPreview from "./link-preview"

const News = ({ news, config }) => {
  const mediaQueryMatch = useMedia(
    mediaQueryList({ mediaPrefix: false })[2],
    false
  )
  const [selectedCategory, setSelectedCategory] = React.useState("all")
  const categories = {}
  config.categories.forEach(name => {
    categories[name] = { articles: [], title: name }
  })
  news.forEach(article => {
    const name = article.categoryInTheNewsOnly
    if (name) categories[name].articles.push(article)
  })
  Object.keys(categories).forEach(
    name =>
      (categories[name].articles = categories[name].articles.sort((a, b) => {
        const dateA = new Date(a.date)
        const dateB = new Date(b.date)
        return dateB - dateA
      }))
  )
  const currentCategory =
    selectedCategory === "all"
      ? Object.values(categories)[0]
      : categories[selectedCategory]
  const formatDate = date => {
    const format = new Date(date).toDateString().split(" ")
    return `${format[1]} ${format[2]}, ${format[3]}`
  }
  const renderCategorySelector = () => {
    const renderItem = (name, title) => (
      <Text
        onClick={() => setSelectedCategory(name)}
        customStyle={{
          cursor: "pointer",
          display: ["inline-block", "inline-block", "inline-block", "block"],
          pb: [0, 0, 0, 2],
          px: [2, 2, 0, 0],
          mb: [1, 1, 1, 0],
          mr: [2, 2, 2, 0],
          userSelect: "none",
          overflowX: "auto",
          ":last-child": {
            pr: 0,
          },
          "-webkit-tap-highlight-color": "transparent",
          background: "white",
          color:
            name === selectedCategory
              ? ["text", "text", "text", "primary"]
              : "text",
          borderBottom: theme => [
            `2px solid ${
              name === selectedCategory ? theme.colors.primary : "transparent"
            }`,
            `2px solid ${
              name === selectedCategory ? theme.colors.primary : "transparent"
            }`,
            `2px solid ${
              name === selectedCategory ? theme.colors.primary : "transparent"
            }`,
            "0px",
          ],
          ":hover": {
            color: ["text", "text", "text", "primary"],
          },
          fontWeight: ["500", "500", "500", "300"],
          fontSize: [4, 4, 4, 3],
        }}
      >
        {title}
      </Text>
    )
    return (
      <div
        sx={{
          ...(mediaQueryMatch === false && {
            position: "relative",
            pr: "20px",
            mb: 4,
            "&:after": {
              color: "primary",
              content: '">>"',
              display: ["block", "block", "block", "none"],
              fontFamily: "body",
              fontSize: 1,
              position: "absolute",
              right: "-10px",
              top: "3px",
            },
          }),
        }}
      >
        <div
          sx={{
            whiteSpace: "nowrap",
            overflowX: "auto",
          }}
        >
          <Text
            customStyle={{
              color: "#888",
              fontSize: 12,
              pb: 2,
              display: ["none", "none", "none", "block"],
            }}
          >
            CATEGORIES
          </Text>

          {renderItem("all", "Latest News")}
          {Object.entries(categories)
            .filter(
              ([name, category]) =>
                category.articles && category.articles.length > 0
            )
            .map(([name, category]) => renderItem(name, category.title))}
        </div>
      </div>
    )
  }

  const renderMainPanel = () => {
    return (
      <Row customStyle={{ px: 0 }}>
        <Column>
          <Text type="h3" customStyle={{ pb: 20, fontSize: 28 }}>
            {currentCategory.title}
          </Text>
          {currentCategory.articles
            .slice(...(selectedCategory === "all" ? [0, 5] : [0]))
            .map(article => (
              <LinkPreview
                link={article.link}
                image={article.image}
                title={article.title}
                subtitle={`${
                  article.publicationTitle
                    ? article.publicationTitle + " - "
                    : ""
                }${formatDate(article.date)}`}
              />
            ))}
          {selectedCategory === "all" && (
            <Text
              customStyle={{
                color: "primary",
                cursor: "pointer",
                mt: [4, 4, 4, 0],
              }}
              onClick={() => setSelectedCategory(currentCategory.title)}
            >
              All {currentCategory.title} {">"}
            </Text>
          )}
        </Column>
      </Row>
    )
  }
  const renderSidePanel = () => {
    return (
      ((!mediaQueryMatch && selectedCategory === "all") || mediaQueryMatch) && (
        <Row customStyle={{ pl: [0, 0, 0, 6], pt: [60, 60, 60, 0] }}>
          <Column>
            {(selectedCategory === "all"
              ? Object.keys(categories).slice(1)
              : Object.keys(categories).filter(
                  name => name !== selectedCategory
                )
            ).map(name => (
              <div sx={{ pb: 60, ":last-child": { pb: 0 } }}>
                <Text
                  type="h3"
                  customStyle={{
                    pb: 20,
                    fontSize: 28,
                  }}
                >
                  {categories[name].title}
                </Text>
                {categories[name].articles.slice(0, 2).map(article => (
                  <LinkPreview
                    link={article.link}
                    image={article.image}
                    title={article.title}
                    subtitle={`${
                      article.publicationTitle
                        ? article.publicationTitle + " - "
                        : ""
                    }${formatDate(article.date)}`}
                    type="secondary"
                  />
                ))}
                <Text
                  customStyle={{
                    color: "primary",
                    cursor: "pointer",
                    mt: [4, 4, 4, 0],
                  }}
                  onClick={() => setSelectedCategory(name)}
                >
                  All {categories[name].title} {">"}
                </Text>
              </div>
            ))}
          </Column>
        </Row>
      )
    )
  }
  return (
    <Container customStyle={{ px: [0, 0, theme => theme.spacing.horizontal] }}>
      <div sx={{ display: ["block", "block", "block", "flex"] }}>
        <div
          sx={{
            pr: [0, 0, 0, "100px"],
          }}
        >
          {renderCategorySelector()}
        </div>
        <Row>
          <Column size={[12, 12, 12, 8]}>{renderMainPanel()}</Column>
          <Column size={[12, 12, 12, 4]}>{renderSidePanel()}</Column>
        </Row>
      </div>
    </Container>
  )
}
export default News
