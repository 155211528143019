/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import { findBySlug } from "../helpers"

import Hero from "../components/hero"
import Page from "../components/page"
import News from "../components/news"
import { paramsCheck } from "../functions/functions"

const InTheNewsPage = ({ data }) => {
  paramsCheck()
  const renderHeroBladePattern = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "news-hero-module",
    })
    const heroHeights = [200, 300]

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: heroBladePatternData.mobileImage,
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        title={heroBladePatternData.title}
      ></Hero>
    )
  }
  const renderNews = () => {
    return (
      <div
        sx={{
          py: theme => [
            theme.spacing.vertical.md,
            theme.spacing.vertical.md,
            theme.spacing.vertical.lg,
          ],
          px: theme => theme.spacing.horizontal,
        }}
      >
        <News
          news={data.allContentfulNews.nodes}
          config={JSON.parse(data.config.content.internal.content)}
        />
      </div>
    )
  }
  return (
    <Page data={data.contentfulPage}>
      {renderHeroBladePattern()}
      {renderNews()}
    </Page>
  )
}

export default InTheNewsPage

export const query = graphql`
  query newsPageQuery {
    contentfulPage(slug: { eq: "news" }) {
      ...PageData
      ...BladePatternData
    }
    allContentfulNews(filter: { type: { eq: "In the news" } }) {
      nodes {
        title
        teaser {
          teaser
        }
        link
        image {
          file {
            url
          }
          description
        }
        categoryInTheNewsOnly
        publicationTitle
        date
      }
    }
    config: contentfulSection(slug: { eq: "news-config" }) {
      content {
        internal {
          content
        }
      }
    }
  }
`
